import React from "react"
import Layout from "../components/layouts/courseLandingLayout"
import Navbar from "../components/navbars/courseLandingNavbar"
import { Container, Col, Row } from "react-bootstrap"
import styled from "styled-components"
import { ReactTypeformEmbed } from "react-typeform-embed"
import syllabus from "../assets/images/syllabus-right.png"
import SyllabusForm from "../components/forms/syllabusForm"

const SyllabusSection = styled.section`
  padding-top: 150px;
  padding-bottom: 200px;

  .title {
    text-align: center;
    color: #fff;
    padding-bottom: 20px;
  }

  .sub-title {
    text-align: center;
    color: #fff;
  }

  background-color: var(--blue);
`

const SyllabusContainer = styled(Container)`
  overflow: hidden;
  width: 90%;
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
  border: 4px solid var(--black);
  box-shadow: -15px 15px var(--black);
  background-color: #fff;
  padding-top: 30px;
`

const Syllabus = ({ location }) => {
  return (
    <Layout>
      <Navbar location={location} />
      <SyllabusSection>
        <Container>
          <h1 className="title">Download Program Syllabus</h1>
          <p className="sub-title">
            Learn more about what it means to become a Web Developer through 1
            on 1 expert mentorship in our affordable, remote coding program.
            Programs capped at 10 students per program.{" "}
          </p>
        </Container>
        <SyllabusContainer>
          <Row>
            <Col md={6}>
              <SyllabusForm close={() => {}} align="center" />
            </Col>
            <Col md={6}>
              <img src={syllabus}></img>
            </Col>
          </Row>
        </SyllabusContainer>
      </SyllabusSection>
    </Layout>
  )
}

export default Syllabus

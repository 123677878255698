import React, { useState } from "react"
import styled from "styled-components"
import { Formik, Form as FormikForm, Field } from "formik"
import Button from "../buttons/button"
import fetch from "isomorphic-unfetch"
import * as Yup from "yup"
import ReactGA from "react-ga"
import Cookies from "js-cookie"

const FormContainer = styled.div`
  h1 {
    text-align: center;
    padding: 0 0 10px 0;
  }
  p {
    font-size: 14px;
  }
`

const ErrorMsg = styled.div`
  color: red;
`

const FieldContainer = styled.div`
  width: 100%;
  font-family: "roboto-mono";
  margin: 15px 0;
  input {
    width: 100%;
  }
`

const fireGTMEvent = () => {
  ReactGA.event({
    category: "CTA - Button",
    action: "Click",
    label: "waitlist-submit",
  })
}

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
})

export default function SyllabusForm({ close }) {
  const [success, setSuccess] = useState(false)

  const submitForm = ({ email, firstName }, ipAddress) => {
    const isBrowser = typeof window !== "undefined"
    const hutk = isBrowser ? Cookies.get("hubspotutk") : null
    const pageUri = isBrowser ? window.location.href : null
    const pageName = isBrowser ? document.title : null

    const postUrl = `https://api.convertkit.com/v3/forms/6076291/subscribe`

    fireGTMEvent()

    const body = {
      email,
      api_key: process.env.GATSBY_CONVERTKIT_API_KEY,
    }

    fetch(postUrl, {
      method: "post",
      body: JSON.stringify(body),
      headers: new Headers({
        "Content-Type": "application/json",
        Accept: "application/json, application/xml, text/plain, text/html, *.*",
      }),
    })
      .then(res => res.json())
      .then(() => {
        setSuccess(true)
        setTimeout(function () {
          close()
        }, 3000)
      })
      .catch(err => {
        console.log(err)
        setSuccess(false)
      })
  }

  const showSuccess = () => {
    if (success) {
      return (
        <>
          <h2>Thanks!</h2>
          <p>
            We just sent you an email with more details on the course. Make sure
            to check your SPAM!
          </p>
        </>
      )
    } else {
      return (
        <FormContainer>
          <h1>Download Syllabus</h1>

          <p>
            Fill out the form below and we'll immediately send you the syllabus
            and some bonus material to help you on your coding journey.
          </p>
          <p>
            We promise it's better than watching 122 YouTube videos on how to
            get started.
          </p>
          <Formik
            initialValues={{
              firstName: "",
              email: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={values => {
              submitForm(values)
            }}
          >
            {({ errors, touched }) => (
              <FormikForm>
                <FieldContainer>
                  <Field placeholder="First Name" name="firstName" />
                  <ErrorMsg>
                    {errors.firstName && touched.firstName ? (
                      <div>{errors.firstName}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>
                <FieldContainer>
                  <Field
                    placeholder="Email Address"
                    name="email"
                    type="email"
                  />
                  <ErrorMsg>
                    {errors.email && touched.email ? (
                      <div>{errors.email}</div>
                    ) : null}
                  </ErrorMsg>
                </FieldContainer>

                <div className="text-center">
                  <Button text={"Submit"} type="submit" />
                </div>
              </FormikForm>
            )}
          </Formik>
        </FormContainer>
      )
    }
  }

  return showSuccess()
}

import React, { useState, useEffect } from "react"
import { Link, navigate } from "gatsby"

import styled from "styled-components"
import { Navbar, Nav, NavDropdown } from "react-bootstrap"

import logoWhite from "../../assets/images/parsity-logo-white.png"
import logoBlack from "../../assets/images/parsity-logo-black.png"
import Button from "../buttons/linkButton"

const StyledNavbar = styled(Navbar)`
  position: fixed;
  top: 0;
  width: 100%;
  padding: 15px;
  z-index: 5000;

  .dropdown-item {
    &:active {
      background-color: var(--blue) !important;
    }
  }

  .navbar-collapse.show {
    background-color: var(--white);
  }

  .logo img {
    height: 60px;
    margin-bottom: 0;
    padding-left: 20px;
  }

  .navbar-nav {
    .nav-link {
      color: var(--black) !important;
    }
  }

  .NavItem {
    margin-right: 15px;
    margin-left: 15px;

    .fuck {
      a {
        color: black !important;
      }
    }
  }

  .black-logo {
    display: none;
  }

  .white-logo {
    display: inline;
  }

  &.isWhite,
  &.isTop {
    background-color: #fff;
    z-index: 5000;
    .white-logo {
      display: none;
    }
    .black-logo {
      display: inline;
    }
  }

  .menu-apply-button {
    margin-top: -20px;
    margin-right: 20px;
    font-size: 14px;
    box-shadow: #000 -5px 5px 0px;
    padding: 10px 15px;
  }

  @media screen and (max-width: 767px) {
    .white-logo {
      display: none;
    }
    .black-logo {
      display: inline;
    }
  }
`

const NavItem = styled.div`
  padding: 8px;
  color: var(--black);
`

export default function PSNavBar({ location, article }) {
  const whiteLocations = [
    "/course/",
    "/events",
    "/events/",
    "/course",
    "/deposit/",
    "/deposit",
    "/prep",
    "/prep/",
    "/apply",
    "/apply/",
    "/faq",
    "/faq/",
    "/landing/focus-course",
    "/landing/focus-course/",
    "/community",
    "/community/",
    "/community/events",
    "/community/events/",
    "/companies/advisory-board/",
    "/companies/advisory-board",
    "/community/hacker-league",
    "/community/hacker-league/",
    "/podcast",
    "/podcast/",
    "/syllabus",
    "/syllabus/",
    "/newsletter",
    "/newsletter/",
    "dev30register",
    "/dev30register",
    "/dev30register/",
    "/",
    "/self-paced-prep",
    "/self-paced-prep/",
  ]

  const isWhite = whiteLocations.includes(location.pathname) || article === true

  const [isTop, setIsTop] = useState(true)

  useEffect(() => {
    document.addEventListener("scroll", () => {
      const fromTop = window.scrollY

      if (fromTop < 100) {
        setIsTop(true)
      } else {
        setIsTop(false)
      }
    })
  })

  const handleDropdownItemClick = page => {
    navigate(`/${page}/`)
  }

  return (
    <StyledNavbar
      expand="lg"
      className={(isWhite ? "isWhite" : null) + " " + (!isTop ? "isTop" : null)}
    >
      <Link to="/">
        <StyledNavbar.Brand className="logo">
          <img className="white-logo" src={logoWhite} alt=""></img>
          <img className="black-logo" src={logoBlack} alt=""></img>
        </StyledNavbar.Brand>
      </Link>
      <StyledNavbar.Toggle aria-controls="basic-navbar-nav" />
      <StyledNavbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <>
            <a
              href="https://calendly.com/jamiewiggins/parsity-admissions-call"
              target="_blank"
            >
              <NavItem className="NavItem">Contact Us</NavItem>
            </a>
            <NavDropdown
              className="NavItem"
              title="Course"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item
                onClick={() => handleDropdownItemClick("course")}
              >
                Online Coding School
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => handleDropdownItemClick("faq")}>
                FAQ
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              className="NavItem"
              title="Prepare"
              id="basic-nav-dropdown"
            >
              <NavDropdown.Item onClick={() => handleDropdownItemClick("prep")}>
                Prep Course
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => handleDropdownItemClick("deposit")}
              >
                Save My Spot
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item
                target="_blank"
                href="https://calendly.com/jamiewiggins/parsity-admissions-call"
              >
                Talk with Admissions
              </NavDropdown.Item>
            </NavDropdown>

            {/* <NavDropdown
              className="NavItem"
              title="Events"
              id="basic-nav-dropdown"
            > */}
            {/* <NavDropdown.Item
                target="_blank"
                href="https://www.eventbrite.com/e/try-coding-one-day-virtual-live-workshop-front-end-programming-9421-tickets-163681027359"
              >
                Try Coding
              </NavDropdown.Item> */}
            {/* <NavDropdown.Divider />
              <NavDropdown.Item
                onClick={() => handleDropdownItemClick("community/events")}
              >
                Online Workshops
              </NavDropdown.Item>
              <NavDropdown.Divider /> */}
            {/* <NavDropdown.Item
                onClick={() => window.open("/open-house", "_blank")}
              >
                Open House
              </NavDropdown.Item> */}
            {/* </NavDropdown> */}

            <a
              href="https://parsity.notion.site/Parsity-Annual-Report-and-Students-Outcomes-6e8513301352431daebc7465c1e02c8e"
              target="_blank"
            >
              <NavItem className="NavItem">Outcomes</NavItem>
            </a>

            {/* <NavDropdown className='NavItem' title="Resources" id="basic-nav-dropdown">
              <NavDropdown.Item onClick={() => handleDropdownItemClick('podcast')}>
                Podcast
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={() => handleDropdownItemClick('articles')}>
                Articles
              </NavDropdown.Item>
            </NavDropdown> */}

            <Link to="/podcast">
              <NavItem className="NavItem" href="#home">
                Podcast
              </NavItem>
            </Link>
            <Link to="/articles">
              <NavItem className="NavItem" href="#home">
                Articles
              </NavItem>
            </Link>
            <Link to="/free-resources">
              <NavItem className="NavItem">Newsletter</NavItem>
            </Link>

            {/* <Link to='/newsletter'>
              <NavItem className='NavItem' href="#home">
                Newsletter
              </NavItem>
            </Link> */}

            <NavItem href="#link">
              <Link to="/apply">
                <Button
                  classToAdd="menu-apply-button"
                  link={true}
                  text={"Apply"}
                />
              </Link>
            </NavItem>
          </>
        </Nav>
      </StyledNavbar.Collapse>
    </StyledNavbar>
  )
}
